import { ParallaxBanner } from "react-scroll-parallax";
import Video from '../../assets/video.mp4'
import { Button } from "antd";
import { FaWhatsapp } from "react-icons/fa";

import './styles.scss'

export default function Header(){
  const background = {
    translateY: [0, 0],
    opacity: [1, 0.3],
    scale: [1.05, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    children: (
      <video autoPlay loop muted playsInline className="background-video">
        <source src={Video} type="video/mp4" />
        {/* Puedes agregar más formatos si tienes, como webm */}
        Tu navegador no soporta el formato de video.
      </video>
    )
  };

  const headline = {
    translateY: [0, 30],
    scale: [1, 2.05, "easeOutCubic"],
    opacity: [1, -1],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="center title">
        <h1 className="headline white">Tu Página Web Profesional Gratis<br/><span className="span-title">¡Impulsa Tu Negocio!</span></h1>
      </div>
    )
  };

  const subTitle = {
    translateY: [0, 100],
    scale: [1, 1, "easeOutCubic"],
    easing: [0.25, 0.2, 0.75, 1.7],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="subtitlediv">
        <div className="first helvet">
          Incluye diseño, dominio y hosting
        </div>
        <div className="sub_header helvet">
          <div>Empieza a destacar online sin costos iniciales y con la tranquilidad de un soporte continuo.</div>
          <div className="button_div"><Button icon={<FaWhatsapp />} onClick={() => {window.open('https://wa.link/4kjgvt', "_blank", "noopener,noreferrer")}}>¡QUIERO MI PÁGINA WEB GRATIS!</Button></div>
        </div>
      </div>
    )
  }

  return (
    <ParallaxBanner
      layers={[background, headline, subTitle]}
      className="full"
    />
  );
};
