import { Parallax } from "react-scroll-parallax";
import Video from '../../assets/video.mp4'
import { Button } from "antd";
import { FcMultipleDevices, FcTemplate, FcPrivacy } from "react-icons/fc";
import { RiTimerFlashFill } from "react-icons/ri";




import './styles.scss'

export default function Sixth(){

	return(
		<div className="sixth">
			<div className="div-sixth">
				<FcMultipleDevices size={50}/>
				<h1>OPTIMIZADO PARA MÓVILES</h1>
				<p>Con más de 50% del tráfico de internet viniendo de teléfonos móviles, nos aseguramos que tu sitio esté totalmente optimizado para móviles.</p>
			</div>

			<div className="div-sixth">
				<FcTemplate size={50}/>
				<h1>DISEÑOS IMPRESIONANTES</h1>
				<p>Utilizamos algunos de los diseños de sitios web más vanguardistas del mundo, creados por destacados diseñadores gráficos.</p>
			</div>

			<div className="div-sixth">
				<RiTimerFlashFill color={'#E20E25'} size={50}/>
				<h1>MÁXIMA VELOCIDAD DE CARGA</h1>
				<p>Aseguraremos que los visitantes de tu sitio web no esperen ni un milisegundo más de lo necesario.</p>
			</div>

			<div className="div-sixth">
				<FcPrivacy size={50}/>
				<h1>LO MÁS NUEVO EN SEGURIDAD</h1>
				<p>Todos nuestros sitios web tienen instaladas las principales funciones de seguridad, incluidas copias de seguridad diarias automatizadas.</p>
			</div>
			
		</div>
	)
}