import { useEffect, useRef } from "react";
import { Parallax } from "react-scroll-parallax";
import Video from '../../assets/video.mp4'
import { Button } from "antd";
import { FaWhatsapp } from "react-icons/fa";
import './styles.scss'

function GradientBackground() {
    const interactiveRef = useRef(null);
  
    useEffect(() => {
      const interBubble = interactiveRef.current;
      let curX = 0;
      let curY = 0;
      let tgX = 0;
      let tgY = 0;
  
      const move = () => {
        curX += (tgX - curX) / 20;
        curY += (tgY - curY) / 20;
        interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
        requestAnimationFrame(move);
      };
  
      const onMouseMove = (event) => {
        tgX = event.clientX;
        tgY = event.clientY;
      };
  
      window.addEventListener('mousemove', onMouseMove);
      move();
  
      return () => {
        window.removeEventListener('mousemove', onMouseMove);
      };
    }, []);
  
    return (
      <div className="gradient-bg">
        <svg viewBox="0 0 100vw 100vw" xmlns="http://www.w3.org/2000/svg" className="noiseBg">
          <filter id="noiseFilterBg">
            <feTurbulence type="fractalNoise" baseFrequency="0.6" stitchTiles="stitch" />
          </filter>
          <rect width="100%" height="100%" preserveAspectRatio="xMidYMid meet" filter="url(#noiseFilterBg)" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" className="svgBlur">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 18 -8"
                result="goo"
              />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
        <div className="gradients-container">
          <div className="g1"></div>
          <div className="g2"></div>
          <div className="g3"></div>
          <div className="g4"></div>
          <div className="g5"></div>
          <div className="interactive" ref={interactiveRef}></div>
        </div>
      </div>
    );
}

export default function Seventh(){
    return(
        <>
            <div className="seventh section-header">
                <p>¡Aprovecha Esta Oportunidad y Lleva tu Negocio al Mundo Digital!</p>
                <p>Empieza Hoy con Tu Página Web Gratis y Un Soporte Completo.</p>
                <div className="button_div"><Button icon={<FaWhatsapp />} onClick={() => {window.open('https://wa.link/4kjgvt', "_blank", "noopener,noreferrer")}}>¡QUIERO MI PÁGINA WEB GRATIS!</Button></div>
            </div>
        </>
    )
}