import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Second from './components/second';
import Third from './components/third';
import Fourth from './components/fourth';
import Fifth from './components/fifth';
import Sixth from './components/sixth';
import Seventh from './components/seventh';
import Eighth from './components/eighth';
import Footer from './components/footer';
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  return (
    <ParallaxProvider>
      <Header/>
      <div className='parallax-image1 sticky'></div>
      <Second />
      <Third />
      <div className='parallax-image2 sticky'></div>
      <Fourth />
      <Fifth />
      <Sixth />
      <div className='parallax-image3 sticky'></div>
      <Seventh />
      <Eighth />
      <Footer />
    </ParallaxProvider>
  );
}

export default App;
