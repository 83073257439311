import { Parallax } from "react-scroll-parallax";
import Video from '../../assets/video.mp4'
import { Button } from "antd";
import { FaWhatsapp } from "react-icons/fa";

import './styles.scss'

export default function Fourth(){

	return(
		<div className="fourth section-header">
			<div className="title">
				Te vamos a contar un gran secreto…
			</div>
			<Parallax scale={[0.7, 1.1]} className="body">
				<div className="one">
					<div>Si no tienes un sitio web profesional, tu negocio tiene más de un 80% menos probabilidades de tener éxito.</div>
				</div>
				<div className="two">
					<div>Una página de Facebook o Instagram simplemente no será suficiente. En promedio, las empresas sin un sitio web profesional fracasan 8 veces más de lo normal.</div>
				</div>
				<div className="three">
					<div><strong>Es 76% más probable</strong> que posibles clientes usen tus servicios si tienes un sitio web profesional para tu negocio.</div>
				</div>
				<div className="four">
					<div>Así es. Tener un sitio web es absolutamente fundamental para hacer <strong>que la gente confíe en ti.</strong> Pero contratar a una agencia web para que construya tu sitio web puede costarte entre 5.000 y 20.000 dólares.</div>
				</div>
			</Parallax>
			<div className="button_div"><Button icon={<FaWhatsapp />} onClick={() => {window.open('https://wa.link/4kjgvt', "_blank", "noopener,noreferrer")}}>¡QUIERO MI PÁGINA WEB GRATIS!</Button></div>
		</div>
	)
}