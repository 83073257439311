import React, { useEffect, useRef, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import Video from '../../assets/video.mp4'
import { Button } from "antd";
import { FaWhatsapp } from "react-icons/fa";
import { TypeAnimation } from 'react-type-animation';

import './styles.scss'

export default function Second(){

	return(
		<div className="second section-header">
			<div className="title-second">
			<TypeAnimation
				sequence={[
					'¿Por qué Conformarte con Menos?\nTu Página Web Completa',
					1000,
					'¿Por qué Conformarte con Menos?\nGratis',
					1000,
					'¿Por qué Conformarte con Menos?\ny Sin Complicaciones',
					1000
				]}
				wrapper="span"
				style={{ whiteSpace: 'pre-line' }}
				speed={70}
				repeat={1}
			/>
			</div>
			{/* <div ref={refTitle}>
				¿Por qué Conformarte con Menos?<br/>
				Tu Página Web Completa, Gratis y Sin Complicaciones
			</div> */}
			<Parallax translateY={[40, -50]}>
				<>
					<div className="sub-first">Sabemos que tu presencia en internet es clave para tu éxito, pero el tiempo y el costo pueden ser un obstáculo. Por eso, te ofrecemos <strong>una solución completa, con todo lo que necesitas para tener una página web profesional</strong> que funcione para ti desde el primer día.
					</div>
				</>
				<>
					<div className="sub-second">
						Beneficios de Nuestro Servicio:
						<ul>
							<li>Hosting y dominio gratis: Sin sorpresas ni cargos ocultos.</li>
							<li>Diseño personalizado: Visual atractivo y adaptado a la identidad de tu negocio.</li>
							<li>Administración continua: Nos encargamos de las actualizaciones, para que tu página esté siempre optimizada.</li>
							<li>Seguridad y soporte: Mantenemos tu sitio seguro y en funcionamiento.</li>
						</ul>
					</div>
				</>
			</Parallax>
		</div>
	)
}