import { Parallax } from "react-scroll-parallax";
import Video from '../../assets/video.mp4'
import { Button } from "antd";
import { FaWhatsapp } from "react-icons/fa";
import { FcVoicePresentation, FcAssistant, FcSlrBackSide } from "react-icons/fc";


import './styles.scss'

export default function Third(){

	return(
		<div className="third">
			<div className="title">
				¿Cómo Funciona?
			</div>
			<div className="body">
				<Parallax translateX={[40, -50]}>
					<div className="one card-third">
						<FcVoicePresentation size={55}/>
						<div><strong>1. Contacta con Nosotros:</strong></div>
						<div>Da clic al botón y un miembro de nuestro equipo se pondrá en contacto para discutir tus necesidades y objetivos.</div>
					</div>
				</Parallax>
				<Parallax translateX={[40, -50]}>
					<div className="two card-third">
						<FcSlrBackSide size={55} />
						<div><strong>2. Diseño Personalizado y Configuración:</strong></div>
						<div>Creamos y configuramos una página web que refleje la identidad de tu negocio y funcione de forma óptima.</div>
					</div>
				</Parallax>
				<Parallax translateX={[40, -50]}>
					<div className="three card-third">
						<FcAssistant size={55} />
						<div><strong>3. Disfruta del Mantenimiento y la Tranquilidad:</strong></div>
						<div>Por una tarifa mensual, nos encargamos de la toda la administración y actualización de tu página, asegurando que siempre esté al día y funcionando perfectamente, así, puedes dedicarte a otras actividades relevantes de tu empresa.</div>
					</div>
				</Parallax>
			</div>
			<div className="button_div"><Button icon={<FaWhatsapp />} onClick={() => {window.open('https://wa.link/4kjgvt', "_blank", "noopener,noreferrer")}}>POSTÚLATE PARA QUE PROFESIONALES CONSTRUYAN TU SITIO WEB GRATIS</Button></div>
		</div>
	)
}