import { Parallax } from "react-scroll-parallax";
import Video from '../../assets/video.mp4'
import { Button } from "antd";
import { FaWhatsapp } from "react-icons/fa";

import './styles.scss'

export default function Fifth(){

	return(
		<div className="fifth">
			<div className="title-fifth">
				Paquete de Mantenimiento Mensual
			</div>
			<div>
				<>
					<div className="sub-first">
						<span><strong>¿Por qué Nuestro Servicio de Administración Continua?</strong></span><br/><br/>
						Una página web sin mantenimiento puede enfrentar problemas de seguridad, lentitud y obsolescencia. Nuestro <strong>servicio mensual de administración</strong> te asegura que tu sitio siempre esté en perfecto estado.
					</div>
				</>
				<>
					<div className="sub-fifth">
						<strong>Incluye:</strong>
						<ul>
							<li>Programación Web</li>
							<li>Actualizaciones de seguridad y rendimiento.</li>
							<li>Administración de hosting</li>
							<li>Mejoras / Actualizaciones en la página web según requerimientos.</li>
							<li>Soporte técnico cuando lo necesites.</li>
						</ul>
					</div>
				</>
			</div>
			<div className="cost">$50 usd / mes<br/><br/><span style={{textDecoration: 'line-through', color: 'grey'}}>$600 usd / anual</span><br/><strong>$450 usd / anual (<span style={{color: '#E20E25'}}>25% de dcto</span>)</strong></div>
			<div className="button_div"><Button icon={<FaWhatsapp />} onClick={() => {window.open('https://wa.link/4kjgvt', "_blank", "noopener,noreferrer")}}>¡QUIERO MI PÁGINA WEB GRATIS!</Button></div>
		</div>
	)
}