import React from "react";
import { FaWhatsapp, FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.content}>
        <div style={styles.icons}>
          <a href="https://wa.link/4kjgvt" target="_blank" rel="noopener noreferrer" style={styles.iconLink}>
            <FaWhatsapp size={30} />
          </a>
          <a href="https://web.facebook.com/misitiowebideal" target="_blank" rel="noopener noreferrer" style={styles.iconLink}>
            <FaFacebook size={30} />
          </a>
        </div>
        <p style={styles.text}>&copy; 2024 Todos los derechos reservados</p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#000", 
    padding: "20px",
    textAlign: "center",
    color: "#fff",
  },
  content: {
    maxWidth: "600px",
    margin: "0 auto",
  },
  icons: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
  },
  iconLink: {
    color: "#fff",
    textDecoration: "none",
    transition: "color 0.3s",
  },
  text: {
    fontSize: "14px",
    margin: "5px 0",
  },
};

export default Footer;
