import { Parallax } from "react-scroll-parallax";
import Video from '../../assets/video.mp4'
import { Button } from "antd";
import { FcMultipleDevices, FcTemplate, FcPrivacy } from "react-icons/fc";
import { RiTimerFlashFill } from "react-icons/ri";
import chevronDown from "../../assets/chevron-down.svg";
import styles from "./styles.module.css";
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import './styles.scss'

export default function Eighth(){
	const AccordionItem = ({ header, ...rest }) => (
		<Item
		  {...rest}
		  header={
			<>
			  {header}
			  <img className={styles.chevron} src={chevronDown} alt="Chevron Down" />
			</>
		  }
		  className={styles.item}
		  buttonProps={{
			className: ({ isEnter }) =>
			  `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
		  }}
		  contentProps={{ className: styles.itemContent }}
		  panelProps={{ className: styles.itemPanel }}
		/>
	);
	return(
		<div className='eighth'>
			<div className='title'>
				<h1>Preguntas Frecuentes (FAQ)</h1>
			</div>
			<div className='faq-list'>
				<Accordion transition transitionTimeout={250}>
					<AccordionItem header="1. ¿Es realmente gratis?">
						Sí, la creación de la página web es completamente gratuita. Solo se cobra una tarifa mensual si decides optar por el servicio de administración continua.
					</AccordionItem>
					<AccordionItem header="2. ¿Qué incluye el servicio de administración mensual?">
						Incluye actualizaciones de seguridad, mantenimiento de servidores, mejoras en la página y soporte técnico personalizado.
					</AccordionItem>
					<AccordionItem header="3. ¿Qué pasa si no quiero el servicio de administración?">
						La creación de la página tendrá un valor representativo sin pago mensual. Sin embargo, no ofrecemos actualizaciones o soporte en este caso.
					</AccordionItem>
					<AccordionItem header="4. ¿Cuánto tiempo toma crear mi página web gratuita?">
						El tiempo de entrega depende de los detalles específicos y del contenido que desees incluir, pero en general, podemos tenerla lista en menos de 7 días hábiles.
					</AccordionItem>
					<AccordionItem header="5. ¿Puedo hacer cambios en el diseño de mi página web después de creada?">
						¡Por supuesto! Nos adaptamos a tus necesidades. Siempre y cuando no altere mas del 40% de la estructura inicial.
					</AccordionItem>
					<AccordionItem header="6. ¿Qué sucede si ya tengo un dominio?">
						Si ya tienes un dominio, podemos integrarlo sin problema a la página que creamos para ti.
					</AccordionItem>
					<AccordionItem header="7. ¿Qué pasa si quiero cancelar el servicio de administración mensual?">
						Puedes cancelarlo en cualquier momento. Sin embargo, ten en cuenta que sin este servicio, no se podria garantizar la disponibilidad de tu página.
					</AccordionItem>
					<AccordionItem header="8. ¿Puedo agregar funciones especiales (como tienda en línea o blog) a mi página web?">
						Sí, tenemos opciones avanzadas. Cuéntanos lo que necesitas, y te daremos una propuesta específica para agregar funciones personalizadas.
					</AccordionItem>
					<AccordionItem header="9. ¿Qué tan seguro es el hosting que ofrecen?">
						Nuestro hosting incluye medidas de seguridad actualizadas y un mantenimiento constante para proteger tu sitio de amenazas y garantizar su disponibilidad.
					</AccordionItem>
					<AccordionItem header="10. ¿Qué pasa si necesito ayuda técnica fuera del horario de atención?">
						Contamos con soporte durante el horario de atención, pero ofrecemos opciones de soporte ampliado por medio de correo electrónico para quienes requieren asistencia continua. Puedes consultarnos para más información.
					</AccordionItem>
					<AccordionItem header="11. ¿Qué sucede si quiero cambiar el contenido de mi sitio web (imágenes, textos, etc.)?">
						Con el servicio de administración mensual, puedes solicitar cambios en el contenido de tu página cuando lo necesites. Si necesitas cambios frecuentes, también podemos hablar de un plan personalizado.
					</AccordionItem>
				</Accordion>
			</div>
		</div>
	)
}